import React, { useState, useEffect } from "react";

import { ShadowTheme } from "../../ShadowTemplate";
import { showSnackbar } from "../../../shared/jquery_wrapper";
import {
  parserCurrencyBRLValue,
  parserValueWithBrlCurrencyAllowZero,
} from "../../../shared/helpers";
import {
  parseAmount,
  calcNewBalance,
  postAdjuster,
  postFileAdjuster,
} from "../../../shared/balance_service";
import AccountBulkBalanceAdjustmentChannel from "../../../../../assets/javascripts/channels/account/bulk_balance_adjustment";

import BalanceForm from "./components/BalanceForm";
import ProgressFeedbackDisplay from "./components/ProgressFeedbackDisplay";
import SubAccountList from "./components/SubAccountList";

import { Box, Button, Card, CardActions } from "@mui/material";

import CheckIcon from "@mui/icons-material/Check";

// last import
import { hot } from "react-hot-loader";

const Index = ({
  accountId,
  balance: initialBalance,
  subAccounts: initialSubAccounts,
  rootSelector,
}) => {
  // form
  const [balanceAccount, setBalanceAccount] = useState(initialBalance);
  const [operation, setOperation] = useState("deposit");
  const [withdrawAll, setWithdrawAll] = useState(false);
  const [amount, setAmount] = useState(parserValueWithBrlCurrencyAllowZero(0));
  const [adjustMethod, setAdjustMethod] = useState("manual");
  const [file, setFile] = useState(null);

  // progress
  const [balanceDeficit, setBalanceDeficit] = useState(0);
  const [progressData, setProgressData] = useState({});
  const [lastProcessedSubAccount, setLastProcessedSubAccount] = useState(null);

  // list
  const [list, setList] = useState(initialSubAccounts);

  // submit
  const [disableConfirmSubmit, setDisableConfirmSubmit] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const subscription = connectBulkBalanceAdjustmentChannel();

    return () => {
      subscription?.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (
      (list.some((subAccount) => subAccount.checked) &&
        adjustMethod === "manual") ||
      (file && adjustMethod === "upload")
    ) {
      setDisableConfirmSubmit(false);
    } else {
      setDisableConfirmSubmit(true);
    }
  }, [list, file, adjustMethod]);

  useEffect(() => {
    const isFinished =
      progressData.progress === -1 ||
      (progressData.total && progressData.progress >= progressData.total) ||
      balanceDeficit < 0;

    if (isFinished) {
      setIsSubmitting(false);
      setAmount(parserValueWithBrlCurrencyAllowZero(0));
    }

    /*istanbul ignore next*/
    if (isFinished && lastProcessedSubAccount?.id) {
      setFile(null);
    }
  }, [progressData.progress]);

  useEffect(() => {
    const sub_account_ids = progressData.sub_account_ids;

    if (sub_account_ids?.length && progressData.status === "success") {
      updateBalanceItems(
        progressData.new_sub_accounts_balance,
        progressData.new_balance
      );

      const lastSubAccount = list.find(
        (subAccount) =>
          subAccount.id === sub_account_ids[sub_account_ids.length - 1]
      );

      setLastProcessedSubAccount(lastSubAccount);
    }
  }, [progressData.sub_account_ids]);

  useEffect(() => {
    if (!isProcessing()) {
      clearBalanceData();
    }
  }, [operation, adjustMethod]);

  useEffect(() => {
    setFile(null);
  }, [adjustMethod]);

  // bulk channel
  const connectBulkBalanceAdjustmentChannel = () => {
    return AccountBulkBalanceAdjustmentChannel({
      accountId: accountId,
      onReceive: (data) => setProgressData(data),
      onConnected: () => setDisableConfirmSubmit(false),
    });
  };

  // submit
  const submitLabel = () => {
    if (isSubmitting) {
      return "Enviando...";
    } else if (adjustMethod === "manual") {
      return `Confirmar (${list.filter((e) => e.checked).length})`;
    } else {
      return "Importar";
    }
  };

  const handleSubmit = () => {
    setIsSubmitting(true);
    clearBalanceData();

    if (adjustMethod === "manual") {
      handleManualUpdate();

      setAmount(parserValueWithBrlCurrencyAllowZero(0));
    } else {
      handleFileUpdate();
    }
  };

  const handleManualUpdate = async () => {
    const numberAmount = withdrawAll
      ? list.reduce((acc, item) => acc + item.balance, 0)
      : parseAmount(amount);
    const filteredList = list.filter((e) => e.checked);
    const subAccountIds = filteredList.map((e) => e.id);
    const tmpBalance = calcNewBalance(
      operation,
      filteredList,
      numberAmount,
      balanceAccount,
      withdrawAll
    );

    if (tmpBalance >= 0) {
      try {
        await postAdjuster(numberAmount, subAccountIds, operation);

        window.scrollTo({ top: 0, behavior: "smooth" });

        showSnackbar({
          content: "Saldo em processo de ajuste!",
          style: "notice",
        });
      } catch (e) {
        setList(initialSubAccounts);
        setProgressData({ progress: -1, status: "error" });
        setIsSubmitting(false);
      }
    } else {
      setBalanceDeficit(tmpBalance);
      setIsSubmitting(false);
    }
  };

  const handleFileUpdate = async () => {
    try {
      await postFileAdjuster(file, operation);

      showSnackbar({
        content: "Saldo em processo de ajuste!",
        style: "notice",
      });
    } catch (e) {
      setProgressData({ progress: -1, status: "error" });
      setIsSubmitting(false);
    }
  };

  const clearBalanceData = () => {
    setBalanceDeficit(0);
    setProgressData({ progress: 0, total: null, status: "" });
    setLastProcessedSubAccount(null);
  };

  // progreess
  const isProcessing = () =>
    progressData.progress > 0 && progressData.progress < progressData.total;

  const updateBalanceItems = (newSubAccountsBalance, newBalance) => {
    const updatedList = list.map((subAccount) => {
      const newBalance = newSubAccountsBalance.find(
        (e) => e.id === subAccount.id
      );

      return newBalance
        ? { ...subAccount, balance: Number(newBalance.balance) }
        : subAccount;
    });

    setBalanceAccount(Number(newBalance));
    initialBalance = Number(newBalance);

    setList(updatedList);
    initialSubAccounts = updatedList;
  };

  return (
    <ShadowTheme rootSelector={rootSelector}>
      <Card style={{ padding: "16px" }}>
        <BalanceForm
          balance={parserCurrencyBRLValue(balanceAccount)}
          amount={amount}
          setAmount={setAmount}
          operation={operation}
          setOperation={setOperation}
          withdrawAll={withdrawAll}
          setWithdrawAll={setWithdrawAll}
          processing={isProcessing()}
          operationProcessing={progressData.operation}
          adjustMethod={adjustMethod}
          setAdjustMethod={setAdjustMethod}
          allSubAccounts={list}
          file={file}
          setFile={setFile}
        />

        <Box sx={{ padding: "0 16px" }}>
          <ProgressFeedbackDisplay
            show={!!Object.keys(progressData).length}
            progressData={progressData}
            lastProcessedSubAccount={lastProcessedSubAccount}
            balanceDeficit={balanceDeficit}
            operation={operation}
            adjustMethod={adjustMethod}
          />
        </Box>

        {adjustMethod === "manual" && (
          <SubAccountList list={list} setList={setList} />
        )}

        {!isProcessing() && (
          <CardActions sx={{ justifyContent: "flex-end" }}>
            <Button
              data-testid="confirm-submit"
              variant="outlined"
              size="large"
              color="primary"
              sx={{ border: "none" }}
              startIcon={isSubmitting ? null : <CheckIcon />}
              onClick={handleSubmit}
              disabled={disableConfirmSubmit || isSubmitting}
            >
              {submitLabel()}
            </Button>
          </CardActions>
        )}
      </Card>
    </ShadowTheme>
  );
};

export default hot(module)(Index);
